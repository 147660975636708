import React from "react";
import { motion } from "framer-motion";
import "./Skills.css";

const skills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "Next.js",
  "Node.js",
  "Git",
  "Tailwind",
  "MongoDB",
  "Redux",
  "GraphQL",
  "Express",
  "PostgreSQL",
  "Python",
  "Flask",
  "Go",
  "Swift",
  "Flutter",
  "Springboot",
  "Kubernetes",
  "Framer Motion",
];

const Skills = () => {
  return (
    <section id="skills">
      <motion.div
      className="skills-section"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      viewport={{ once: true }}
    >
      <div className="flex flex-col items-center justify-center bg-gray-50">
      <div class = "text-4xl w-full lg:text-5xl pt-10 md:pt-0 font-extrabold">My Skills</div>
      <div className="skills-container mt-4">
        {skills.map((skill, index) => (
          <motion.div
            key={index}
            className="skill-box"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            {skill}
          </motion.div>
        ))}
      </div>
      </div>
      
    </motion.div>
    </section>
  );
};

export default Skills;
