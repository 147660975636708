import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import { Navigation, Pagination, FreeMode } from "swiper/modules";

export default function Timeline() {
  const events = [
    { year: "2018", company: "Freshworks Inc.", title: "Intern", description: [
      "Engineered an end-to-end booking feature for Freshdesk, enhancing inquiry management.",
      "Optimized performance testing to ensure production-grade service delivery.",
      "Streamlined backend services to reduce response times and enhance efficiency."
    ] },
    { year: "2019", company: "Techlivz Co.", title: "Intern", description: [
      "Automated deployments and improved scalability using CI/CD pipelines with GitLab, Docker, and Kubernetes.",
      "Designed a channel manager to synchronize real-time bookings across platforms.",
      "Implemented efficient deployment strategies for better software delivery."
    ] },
    { year: "2023", company: "Khoros R&D", title: "Senior Software Engineer", description: [
      "Integrated AWS Translation Service to enable real-time multilingual post translation for agents.",
      "Led the development of a unified conversation panel using GWT, React, and Java to improve accessibility.",
      "Optimized a bulk processing system with DynamoDB and SQS, boosting performance by 25% and cutting post-processing time by 50%."
    ] },
    { year: "2023", company: "Coral Labs Purdue", title: "Grduate Research Student", description: [
      "Engineered a pipeline for 3D object reconstruction, capturing real-world objects and completing mesh processing.",
      "Developed a Flask-based backend using Open3D for point cloud processing with real-time visualization.",
      "Integrated a Three.js frontend to enable dynamic and interactive 3D representations."
    ] },
    { year: "2024", company: "Mrcc Purdue", title: "Graduate Research Assistant", description: [
      "Optimized MRCC’s cloud and HPC infrastructure, improving scalability by 30% and reducing downtime by 50%.",
      "Engineered data-driven climate tools for projects like IMPACT2, enhancing agricultural planning and climate risk management.",
      "Deployed the Corn Growing Degree Day Tool in Kubernetes to help farmers assess climate risks and optimize resources."
    ]}
  ];


  const pagination = {
    clickable: true,
    // renderBullet: function (index, className) {
    //   return '<span class="' + className + '">' + (index + 1) + '</span>';
    //   return `<span class="text-sm font-bold text-white">`+ (index + 1) +`</span>`;
    // },
  };

  return (
    <section id="experience" className="w-full py-10 overflow-x-hidden h-3xl">
      <div class = "text-4xl w-full lg:text-5xl pt-10 md:pt-0 font-extrabold">Experience</div>

      {/* Swiper Slider */}
      <Swiper
        modules={[Navigation, Pagination, FreeMode]}
        spaceBetween={20}
        slidesPerView="auto" // ✅ Auto width per slide
        centeredSlides={true} // ✅ Centers active slide
        freeMode={true} // ✅ Smooth scrolling, no strict snapping
        navigation
        initialSlide={events.length - 1}
        pagination={pagination}
        className="px-6"
        style={{ overflow: "visible", marginTop: "50px" }} // ✅ Prevent cropping
      >
        {events.map((event, index) => (
          <SwiperSlide key={index} className="w-auto flex justify-center">
            <div className="relative flex flex-col items-center bg-white shadow-lg rounded-2xl p-3 border border-gray-200 min-w-[300px] max-w-[350px]">
              {/* Year Badge */}
              <div className="absolute -top-5 bg-sky-500 text-black text-lg font-semibold px-4 py-1 rounded-full">
                {event.year}
              </div>
              {/* Company Name */}
              <h3 className="text-xl font-bold text-gray-800 mt-6">{event.company}</h3>
              {/* Job Title */}
              <p className="text-sky-600 font-bold">{event.title}</p>
              {/* Description */}
              {event.description.map(desc => (
                <li className="text-gray-600 text-sm mt-2 text-left">* {desc}</li>
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}
