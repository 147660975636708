import React, { useState } from "react";
import { X } from "lucide-react"; // Add if needed for closing functionality
// import { HoverEffect } from "./ui/HoverCard";

const Education = () => {
  const [activeTab, setActiveTab] = useState("stats");
  const educationData = [
    {
      title: "High School",
      name: "NSN Group of Schools",
      description: ["Computer Science with Math", "Ranked - 2/300", "199/200 marks on Boards"],
      icon: "🎓",
      year: "2013 - 2015",
    },
    {
      title: "Under Graduate",
      name: "College Of Engineering Guindy",
      description: ["B.E Computer Science engineering", "CGPA 8.98/10.0", "First class with distinction"],
      icon: "📘",
      year: "2015 - 2019",
    },
    {
      title: "Post Graduate",
      name: "Purdue University Main Campus",
      description: ["MS in Computer Science","CGPA: 3.7/4.0", "CSGSB Member",],
      icon: "🤖",
      year: "2023 - 2025",
    }
  ];
  return (
    <section id="education">      <div class = "text-4xl w-full lg:text-5xl pt-10 md:pt-0 font-extrabold mt-5">Education</div>
    <div class="flex flex-col md:flex-row justify-center gap-6 p-8 bg-gray-50">
        {     
            educationData.map((item) => (
                <div class="bg-dark border border-gray-200 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6 max-w-sm hover:bg-gray-100 flex flex-col text-left cursor-pointer">
                    <h2 class="text-2xl font-bold mb-2" style={{ color: '#52BDFF' }}>
                        {item.title}
                    </h2>
                    <p class="pr-2 text-gray-200 mb-4">{item.name}</p>
                    <ul class="list-disc text-white space-y-2 text-gray-700 flex-grow">
                        {item.description.map((iitem, index) => (
                        <li key={index}>{iitem}</li>
                        ))}
                    </ul>
                    <footer class="mt-4 text-gray-500 font-semibold self-end">{item.year}</footer>
                </div>
            ))
        }
        </div>
        {/* <div className="max-w-5xl mx-auto px-8">
          <HoverEffect items={educationData} />
        </div> */}
        </section>
  );
};

export default Education;
