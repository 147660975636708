import React from "react";
import "./Project.css";

const Project = () => {
  const projects = [
    {
      title: "Cloud Sentinel: Malware Detection for Crowdsensing",
      description:[
        "Built a cloud-based malware detection system to improve performance and security.",
        "Enhanced protection against fake-sensing, anti-jamming, and privacy breaches."
      ],
      icon: "./assets/projects/mcs.webp", // Replace with actual icon
    },
    {
      title: "Kurukshetra TechFest",
      description: [
        "Developed a website and mobile app with inventory management and gamified rewards.",
        "Created interactive games to boost engagement and participation."
      ],
      icon: "./assets/projects/kuruk.webp"
    },
    {
      title: "Mars E-commerce Website",
      description: [
        "Built a full-stack platform with inventory, payments, and real-time delivery tracking.",
        "Streamlined backend processes for better efficiency and user experience."
      ],
      icon: "./assets/projects/mars.webp"
    },
    {
      title: "Project Kirana: Empowering Local Retail",
      description: [
        "Designed a business model to digitize local stores and streamline workflows.",
        "Implemented a recommender system to boost sales and personalize experiences."
      ],
      icon: "./assets/projects/kirana.webp"
    },
    {
      title: "Smart Scheduler: Genetic Algorithm for Conferences",
      description: [
        "Created a scheduling tool using Genetic Algorithms to optimize session allocation.",
        "Improved time management with performance analysis of historical data."
      ],
      icon: "./assets/projects/genetic.webp"
    },
    {
      title: "Robust Regression: Verified Housing Predictions",
      description: [
        "Built regression models with Z3 Solver for domain-specific predictions.",
        "Improved system reliability with scalable Dockerized pipelines."
      ],
      icon: "./assets/projects/z3.webp"
    },
  ];

  return (
    <section id="projects" className="projects-section bg-gray-950">
      <div class = "text-4xl text-white w-full md:text-5xl pt-10 md:pt-0 font-extrabold">Projects</div>
      <div className="container mt-5">
  {projects
    .reduce((rows, project, index) => {
      if (index % 2 === 0) {
        // Start a new row every 2 projects
        rows.push([]);
      }
      rows[rows.length - 1].push(project);
      return rows;
    }, [])
    .map((row, rowIndex) => (
      <div className="row mb-3" key={rowIndex}>
        {row.map((project, index) => (
          <div className="col-md-6" key={index}>
            <div
              className="project-card card mb-3"
              style={{
                // height: "200px"
                overflow: "hidden" // Ensures content does not overflow
              }}
            >
              <div className="row no-gutters h-100">
                <div className="col-md-4">
                  <img
                    src={project.icon}
                    className="h-100 w-100 object-cover"
                    alt={project.title}
                    style={{
                      objectFit: "cover" // Ensures the image fills horizontally while keeping aspect ratio
                    }}
                  />
                </div>
                <div className="col-md-8">
                  <div
                    className="card-body d-flex flex-column justify-content-center"
                    style={{
                      height: "100%", // Ensures the card body takes the full height
                    }}
                  >
                    <h5 className="text-white">{project.title}</h5>
                      {
                        project.description.map(desc => (
                          <p style={{ marginBottom: '0px'}}>- {desc}</p>
                        ))
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    ))}
</div>

    </section>
  );
};

export default Project;
