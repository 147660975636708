import React from "react";
import { BentoGrid, BentoGridItem } from "./ui/BentoGrid";

const Skeleton = () => (
  <div
    className="flex flex-1 w-full h-full min-h-[6rem] rounded-xl bg-gradient-to-br from-neutral-200 dark:from-neutral-900 dark:to-neutral-800 to-neutral-100 border-4 border-sky-500">
  </div>
);

const items = [
  {
    id: 1,
    title: "I prioritize client collaboration, fostering open communication ",
    // description: "hello there",
    imgClassName: "w-full h-full",
    titleClassName: "justify-end p-4",
    img: "./assets/new/b1.svg",
    spareImg: "",
    className: "md:col-span-6 md:row-span-3",
  },
  {
    id: 2,
    title: "My Tech stack",
    description: "",
    className: "md:col-span-6 md:row-span-2",
    imgClassName: "",
    titleClassName: "justify-start",
    img: "",
    spareImg: "",
  },
  {
    id: 3,
    // title: "Purs",
    // description: "Join the quest for understanding and enlightenment.",
    // header: <Skeleton />,
    className: "md:col-span-2 md:row-span-1",
    img: "",
    imgClassName: "",
  },
  {
    id: 4,
    title: "Publications",
    description: "",
      // "Understand the impact of effective communication in our lives.",
    // header: <Skeleton />,
    className: "md:col-span-4 md:row-span-2",
    titleClassName: "",
    pubItems: [
      {
        title: "Patent # 11438289",
        status: "Published",
        statusColor: "bg-green-100 text-green-800",
        dueDate: "September 2023",
        createdBy: "Justia Patents",
        link: "https://patents.justia.com/patent/11438289"
      },
      {
        title: "Patent # 11128589",
        status: "Published",
        statusColor: "bg-green-100 text-green-800",
        dueDate: "July 2021",
        createdBy: "Justia Patents",
        link:"https://patents.justia.com/patent/11128589",
      },
      {
        title: "Genetic Algorithm",
        status: "Published",
        statusColor: "bg-green-100 text-green-800",
        dueDate: "July 2024",
        createdBy: "AIWARE 2024",
        link: "https://doi.org/10.1145/3664646.3665086"
      },
    ]
  },
  {
    id: 5,
    // title: "The Joy",
    // description: "Experience the thrill of bringing ideas to life.",
    // header: <Skeleton />,
    className: "md:col-span-3 md:row-span-2",
    mainImg: "./assets/awsdev.png",
    // img: "./assets/awsdev.png",
    imgClassName: "w-full h-full",
  },
  {
    id: 6,
    title: "Do you want to start a project together?",
    description: "",
    imgClassName: "",
    titleClassName: "justify-center md:max-w-full max-w-50 text-center",
    img: "",
    spareImg: "",
    className: "md:col-span-5 md:row-span-2"
  },
  {
    id: 7,
    title: "Tech enthusiast with a passion for development.",
    description: "",
    className: "lg:col-span-4",
    imgClassName: "w-full h-full",
    titleClassName: "justify-start p-3",
    img: "./assets/new/grid.svg",
    spareImg: "./assets/new/b4.svg",
  },
];

const Dashboard = () => {
  return (
    <section id="Dashboard" className="bg-gray-900 pt-5 pb-5">
      <BentoGrid className="max-w-6xl mx-auto" id="bentogrid">
        {items.map((item, i) => (
          <BentoGridItem
            id={item.id}
            key={i}
            title={item.title}
            description={item.description}
            header={item.header}
            icon={item.icon}
            className={item.className} 
            img={item.img}
            imgClassName={item.imgClassName}
            spareImg={item.spareImg}
            titleClassName={item.titleClassName}
            pubItems={item.pubItems}
            mainImg={item.mainImg}
          />
            
        ))}
      </BentoGrid>
    </section>
  );
}
export default Dashboard;