import React, {useState} from 'react';
import { cn } from "../../lib/utils";
import MagicButton from "./MagicButton";
// import { GlobeDemo } from './GlobeDemo';
import { BackgroundGradientAnimation } from './BgGradient';

import { CopyCheck, CopyIcon, Linkedin } from "lucide-react";
import InfiniteCards from './InfiniteCards';

export const BentoGrid = ({
  className,
  children
}) => {
  return (
    (<div id="bentogrid"
      className={cn(
        "grid sm:grid-cols-3 max-w-screen-2xl md:grid-cols-5 lg:grid-cols-8 xl:grid-cols-12 w-full gap-2 md:auto-rows-[8rem] md:auto-cols-[8rem]",
        // 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-9 xl:grid-cols-9 gap-2 max-w-8xl mx-auto auto-rows-[6rem] auto-cols-[6rem]',
        className
      )}>
      {children}
    </div>)
  );
};

export const BentoGridItem = ({
  id,
  className,
  title,
  description,
  header,
  icon,
  img,
  imgClassName,
  spareImg,
  titleClassName,
  pubItems,
  mainImg
}) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    const text = "vigneshwaran.dharmalingam@gmail.com";
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  return (
    <div
    className={cn(
      // remove p-4 rounded-3xl dark:bg-black dark:border-white/[0.2] bg-white  border border-transparent, add border border-white/[0.1] overflow-hidden relative
      "row-span-1 relative overflow-hidden rounded-3xl  group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none justify-between flex flex-col space-y-4",
      className
    )}
    style={{
      //   add these two
      //   you can generate the color from here https://cssgradient.io/
      background: "rgb(4, 17, 29)",
      backgroundColor:
        "linear-gradient(90deg, rgba(4,7,29,1) 0%, rgba(12,14,35,1) 100%)",
      border: "1px solid #404040"
    }}
  >
    {/* add img divs */}
    <div className={`${id === 6 && "flex justify-center"} h-full`} id="full-card-bento">
      <div className={`w-full h-full absolute ${id===5 ? "p-4": ""}`}>
        {/* {img} */}
        {img && (
          <img
            src={img}
            alt={img}
            className={cn(imgClassName, "object-cover object-center ")}
          />
        )}
      </div>
      <div
        className={`absolute right-0 -bottom-5 ${id === 5 && "w-full opacity-80"
          } `}
      >
        {spareImg && (
          <img
            src={spareImg}
            alt={spareImg}
            //   width={220}
            className="object-cover object-center w-full h-full"
          />
        )}
      </div>
      {id === 6 && (
        // add background animation , remove the p tag
        <BackgroundGradientAnimation>
          <div className="absolute z-50 inset-0 flex items-center justify-center text-white font-bold px-4 pointer-events-none text-3xl text-center md:text-4xl lg:text-7xl"></div>
        </BackgroundGradientAnimation>
      )}

      <div
        id="bento-card-content"
        className={cn(
          titleClassName,
          "transition duration-200 relative md:h-full min-h-20 flex flex-col px-5 lg:p-2",
          id !== 2 && id !== 4 && "group-hover/bento:-translate-y-1"
        )}
      >
        {/* change the order of the title and des, font-extralight, remove text-xs text-neutral-600 dark:text-neutral-300 , change the text-color */}
        <div className="font-sans font-extralight md:max-w-32 md:text-xs lg:text-base text-sm text-[#C1C2D3] z-10">
          {description}
        </div>
        {/* add text-3xl max-w-96 , remove text-neutral-600 dark:text-neutral-300*/}
        {/* remove mb-2 mt-2 */}
        <div
          className={`font-sans text-sm max-w-90 text-white z-1 mt-2 font-bold ${id === 7 || id === 2 ? "text-start md:text-xl": "text-center md:text-2xl"}`}
        >
          {title}
        </div>

        {/* for the github 3d globe */}
        {/* {id === 2 && <GlobeDemo />} */}

        {id === 3 && (
          <div className="text-white mx-auto my-auto cursor-pointer" id="icon-container">
            <a target='_blank' href="https://www.linkedin.com/in/vigneshwaran-dharmalingam/">
              <Linkedin size={60}/>
            </a>
          </div>
        )}

        {id === 5 && (
          <img 
            src={mainImg}
            className='text-white mx-auto my-auto text-[20px] cursor-pointer scale-110'
          />
        )}

        {id === 2 && <InfiniteCards />}
        
        {id === 6 && (
          <div className="mt-5 relative">
            {/* button border magic from tailwind css buttons  */}
            {/* add rounded-md h-8 md:h-8, remove rounded-full */}
            {/* remove focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50 */}
            {/* add handleCopy() for the copy the text */}
            <div
              className={`absolute -bottom-5 right-0 ${copied ? "block" : "block"
                }`}
            >
              {/* <img src="/confetti.gif" alt="confetti" /> */}
              {/* <Lottie options={defaultOptions} height={200} width={400} /> */}
            </div>

            <MagicButton
              title={copied ? "Email is Copied!" : "Copy my Email"}
              icon={copied ? <CopyCheck />: <CopyIcon />}
              position="left"
              handleClick={handleCopy}
              otherClasses="!bg-[#161A31]"
            />
          </div>
        )}


        { id === 4 && (
          <div className="max-w-xs p-2 bg-transparent">
            <ul role="list" className="divide-y divide-gray-200">
              {pubItems.map((project, index) => (
                <a target='_blank' href={project.link}>
                  <li key={index} className="flex items-center justify-between border-transparent hover:bg-gray-800 hover:text-black rounded-lg p-2 transition">
                  <div className="flex flex-col">
                    <div className="flex items-center space-x-2">
                      <h3 className="text-sm font-semibold text-white">{project.title}</h3>
                      <span
                        className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${project.statusColor}`}
                      >
                        {project.status}
                      </span>
                    </div>
                    <p className="text-sm text-gray-400 text-left">
                      {project.dueDate} · {project.createdBy}
                    </p>
                  </div>
                </li>
                </a>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
  );
};
