import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-8">
      <div className="max-w-7xl mx-auto px-4">
        {/* Top Links */}
        <div className="flex justify-center space-x-6 text-sm mb-6">
          <a href="#" className="hover:text-white transition">
            Profile
          </a>
          <a href="#skills" className="hover:text-white transition">
            Skills
          </a>
          <a href="#featured" className="hover:text-white transition">
            Featured
          </a>
          <a href="#experience" className="hover:text-white transition">
            Experience
          </a>
          <a href="#projects" className="hover:text-white transition">
            Projects
          </a>
          <a href="#education" className="hover:text-white transition">
            Education
          </a>
        </div>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-6 text-lg mb-6">
          {/* <a href="#" className="hover:text-white transition">
            <StackIcon name="meta" />
          </a>
          <a href="#" className="hover:text-white transition">
          <StackIcon name="github" />
          </a> */}
          {/* <a href="#" className="hover:text-white transition">
          <StackIcon name="youtube" />
          </a>
          <a href="#" className="hover:text-white transition">
            <FaGithub />
          </a>
          <a href="#" className="hover:text-white transition">
            <FaYoutube />
          </a> */}
        </div>

        {/* Bottom Text */}
        <div className="text-center text-sm">
          &copy; 2025 Vigneshwaran Dharmalingam. All rights reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
