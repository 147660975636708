import './App.css';
import Featured from './components/Featured'
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Project from './components/Project';
import Skills from './components/Skills';

import Navbar from './components/Navbar';
import Timeline from './components/Timeline';
import Education from './components/Education';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <Navbar />
        <Profile />
        <Dashboard />
        <Skills />
        <Featured /> 
        <Timeline />
        <Project />
        <Education />
        <Footer />
    </div>
  );
}

export default App;
