import React from 'react';
import { Menu, X } from 'lucide-react'; // Icons for hamburger menu

const Contact = ({openContact, setOpenContact}) => {
    return (
        <section
  className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-6 transition-transform duration-500 ease-in-out ${
    openContact ? "translate-y-0 opacity-100" : "translate-y-full opacity-0 pointer-events-none"
  }`}
  style={{ zIndex: "30" }}
>
  <div className="absolute bottom-0 w-full sm:w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 bg-gray-800 text-white mx-auto rounded-lg shadow-lg p-8">
    
    {/* Close Button */}
    <button
      className="absolute top-4 right-4 text-white hover:text-gray-400"
      onClick={() => setOpenContact(false)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x">
        <path d="M18 6 6 18"></path>
        <path d="m6 6 12 12"></path>
      </svg>
    </button>

    {/* Title */}
    <div className="text-center mb-8">
      <div className="text-5xl text-white mb-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-12 h-12 mx-auto"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.75 8.25l-9 6-9-6M21.75 15.75l-9 6-9-6"
          />
        </svg>
      </div>
      <h2 className="text-3xl font-extrabold text-white">Contact Me</h2>
    </div>

    {/* Contact Form */}
    <form
      action="https://formsubmit.co/vigneshwaran.dharmalingam@gmail.com"
      method="POST"
      className="w-full max-w-lg mx-auto bg-gray-800 p-6 rounded-lg shadow-lg"
    >
      {/* Name Input */}
      <div className="mb-4">
        <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">
          Your Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter your name"
          className="w-full p-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:ring-2 focus:ring-green-400"
          required
        />
      </div>

      {/* Email Input */}
      <div className="mb-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
          Your Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email"
          className="w-full p-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:ring-2 focus:ring-green-400"
          required
        />
      </div>

      {/* Message Input */}
      <div className="mb-4">
        <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">
          Your Message
        </label>
        <textarea
          id="message"
          name="message"
          rows="4"
          placeholder="Enter your message"
          className="w-full p-3 bg-gray-700 text-white border border-gray-600 rounded-lg focus:ring-2 focus:ring-green-400"
          required
        ></textarea>
      </div>

      {/* Submit Button */}
      <button
        type="submit"
        className="w-full py-3 px-4 bg-green-500 text-white font-medium rounded-lg hover:bg-green-600 transition-all flex justify-center items-center"
      >
        Send Message
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5 ml-2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 9.75l-6.5 6.5m6.5-6.5l-6.5-6.5m13 6.5H3.75"></path>
        </svg>
      </button>
    </form>
  </div>
</section>

    );    
}

export default Contact