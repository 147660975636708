import React, {useState, useEffect} from 'react';
import './Navbar.css'
import { Menu, X } from 'lucide-react'; // Icons for hamburger menu
import Contact from './Contact';

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [openContact, setOpenContact] = useState(false); 

    useEffect(() => {
      const handleScroll = () => {
        setScrolled(window.scrollY > 50);
      };
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
      
    }, []);


    const handleContactFromSide = () => {
        setOpenContact(true);
        setMenuOpen(false);
    }

    return (
      <><div class = "h-6 fixed z-50 md:relative block w-full z-50" style={{ position: "absolute", top: '0', background:'#52BDFF'}}></div>
        <div id="navbar" className={`main-nav mt-5 d-flex align-items-center justify-content-between ${scrolled ? "scrolled" : ""}`}>
          <span className = "block lg:hidden inline-block rounded-md animate__animated xl:mr-6 animate__fadeInDown border-4 border-gray-900 text-gray-900 select-none p-2 inline-block text-2xl xl:text-3xl font-extrabold">
                  VD
          </span>
          <span className='hidden lg:block' style={{margin:"0px 40px"}}>
            <span className = "inline-block rounded-md animate__animated xl:mr-6 animate__fadeInDown border-4 border-gray-900 text-gray-900 select-none p-2 inline-block text-2xl xl:text-3xl font-extrabold">
                  VD
            </span>
              <a  
                  href="#projects"
                  className={`inline-block animate__animated animate__fadeInDown kill-link-style delay1 ml-6 mr-3 duration-300 text-2xl xl:text-3xl cursor-pointer font-extrabold underline--magical ${
                      scrolled ? "underline--magical2" : ""
                  }`}
              >
                  Projects
              </a>
              <a
                  href="#experience"
                  className={`inline-block animate__animated animate__fadeInDown kill-link-style delay2 ml-6 mr-3 duration-300 text-2xl xl:text-3xl cursor-pointer underline--magical font-extrabold ${
                      scrolled ? "underline--magical2" : ""
                  }`}
              >
                  Experience
              </a>
              <a
                  href="#skills"
                  className={`inline-block animate__animated animate__fadeInDown kill-link-style delay3 ml-6 mr-3 duration-300 text-2xl xl:text-3xl cursor-pointer underline--magical font-extrabold ${
                      scrolled ? "underline--magical2" : ""
                  }`}
              >
                  Skills
              </a>
            </span>
          <span className='hidden lg:block'>
            <span onClick={() => setOpenContact(true)}  className = "inline-block animate__animated animate__flipInX delay4 ml-6 mr-3 duration-300 float-right text-2xl xl:text-3xl cursor-pointer border-4 px-2 xl:px-4 p-2 border-gray-900 hover:bg-gray-900 hover:text-gray-50 rounded-md font-extrabold">Contact Me</span>
            <button className='rs-button'><a href='/vigneshwaran_dharmalingam_resume.pdf' target='_blank'>Résumé</a></button>
          </span>
          {/* Mobile Menu Button */}
          <button className="lg:hidden text-gray-900" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <X size={35} /> : <Menu size={35} />}
          </button>
        </div>
        <div
        className={`fixed top-0 right-0 h-screen w-80 bg-white shadow-lg p-6 transform transition-all duration-500 ease-in-out ${
          menuOpen ? "translate-x-0 opacity-100" : "translate-x-full opacity-0 pointer-events-none"
        }`}
        style={{ zIndex: 50 }}
      > 
        <div style={{ marginTop: '100px', display: 'grid'}}>
        
        <a href="#projects" className="py-2 text-gray-900 text-lg font-bold hover:text-blue-600" onClick={() => setMenuOpen(false)}>
              Projects
            </a>
            <a href="#experience" className="py-2 text-gray-900 text-lg font-bold hover:text-blue-600" onClick={() => setMenuOpen(false)}>
              Experience
            </a>
            <a href="#skills" className="py-2 text-gray-900 text-lg font-bold hover:text-blue-600" onClick={() => setMenuOpen(false)}>
              Skills
            </a>
            <button className="mt-4 border-4 border-gray-900 px-4 py-2 text-gray-900 font-bold rounded-md hover:bg-gray-900 hover:text-white" onClick={handleContactFromSide}>
              Contact Me
            </button>
            <button className="mt-2 border-4 border-gray-900 px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-800">
              <a href='/vigneshwaran_dharmalingam_resume.pdf' target='_blank'>
                Résumé
              </a>
            </button>
            </div>
      </div>
      {openContact && (
  // <section
  //   className="fixed top-0 right-0 h-3xl w-3xl bg-gray-900 border border-green-400 shadow-lg transition-transform duration-500 ease-in-out transform translate-y-0"
  //   style={{ zIndex: 50 }}
  // >
    <Contact openContact={openContact} setOpenContact={setOpenContact} />
)}

      </>
    );
}

export default Navbar;