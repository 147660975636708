import React from "react";
import "./Home.css";

const featured = [
  {
    title:"Vipsline",
    img:"./assets/techlivz.png",
    description:["An all-in-one, secure cloud-based software to schedule appointments, Manage Business and Promote your Business"],
    tags:['Java', 'Docker', "Angular"],
    link: "https://business.vipsline.com/home"
  },{
    title:"Khoros",
    img:"./assets/khoros.png",
    description:['The Khoros Social Media Management solution brings together all of the brand’s social media accounts and campaigns — and all the people who manage them — into one place.'],
    tags:['AWS', 'Java', 'React', 'Docker'],
    link: "https://khoros.com/"
  },{
    title:"Mrcc Purdue",
    img:"./assets/mrcc.png",
    description:['Providing high-quality climate data, tools, interactive maps, and customized services for the Midwest and the nation since 1982.'],
    tags:['Kubernetes', 'Vue', 'HPC', 'PHP'],
    link: "https://mrcc.purdue.edu/"
  }
];

const Featured = () => {
  return (
    <div id="featured" class = "p-4 md:p-20 bg-gray-900 text-gray-50">
      <div class = "sm:w-12/12 animate__animated animate__fadeIn delay2 text-center md:w-12/12 col-centered">
        <div class = "text-4xl w-full md:text-5xl lg:text-6xl pt-10 md:pt-0 font-extrabold">Featured Work</div>
            <div class = "grid grid-cols-12 mt-8">
              {
                featured.map((item) => (
                  <div class = "col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-4 p-4 shadow-2xl hover:scale-105 transition-transform duration-300">
                    <a href = {item.link} target = "_blank" class = "kill-link-style">
                      <div class = "cursor-pointer move-up-slightly duration-200 shadow-md hover:shadow-xl">
                      <div class = "project-picture rounded-t-2xl h-full w-full freshworks-demo">
                        <img src={item.img} className="banner-img"/>
                      </div>
                        <div class="px-6 py-4 ">
                          <div class="font-bold text-xl mb-2">{item.title}</div>
                              {item.description.map(description => (
                                <p class="text-gray-400 text-base text-left">
                                  {description}
                                </p>
                              ))}
                        </div>  
                        <div class="">
                              {item.tags.map(tag => (
                                <span class="inline-block bg-sky-500 text-black rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">{tag}</span>
                              ))}
                        </div>
                      </div>
                    </a>
                  </div>   
                ))
              }
            </div>
        </div>
      </div>
  );
};

export default Featured;
