"use client";
import React from 'react'
import { InfiniteMovingCards } from "./InfiniteMovingCards";

const testimonials = [
    {
      name: "Charles Dickens",
      title: "ReactJS",
      icon: "reactjs"
    },
    {
      name: "William Shakespeare",
      title: "VueJS",
      icon: "vuejs"
    },
    {
      name: "Edgar Allan Poe",
      title: "Docker",
      icon: "docker"
    },
    {
      name: "Jane Austen",
      title: "Kubernetes",
      icon: "kubernetes"
    },
    {
      name: "Herman Melville",
      title: "AWS",
      icon: "aws"
    },
  ];
  

const InfiniteCards = () => {

    return (
        <div className="flex flex-col items-center max-lg:mt-10">
            <div
            // remove bg-white dark:bg-black dark:bg-grid-white/[0.05], h-[40rem] to 30rem , md:h-[30rem] are for the responsive design
            className="h-[50vh] md:h-[30rem] rounded-md flex flex-col antialiased  items-center relative overflow-hidden w-md"
            >
            <InfiniteMovingCards
                items={testimonials}
                direction="right"
                speed="slow"
            />
            </div>
        </div>
    );
}


export default InfiniteCards;